<template>
    <div>
        <h2>Mollie SEPA</h2>
        <vs-divider/>
        <br/>
        <vx-card>
            <p>IBAN Client:</p>
            <vs-input class="inputx w-full" v-model="mollie_iban" /><br/>

            <p>Montant en Euro:</p>
            <vs-input class="inputx w-full" v-model="prelevement" type="number" /><br/>

            <p>Nombre mois de répétition:</p>
            <vs-input class="inputx w-full" type="number" v-model="mollie_repeat" min="1" /><br/>
            <br/>
            
            <template v-if="mollie_repeat">
                <template v-if="prelevement">
                    <p class="center text-center">Soit <strong>{{ (parseFloat(prelevement)/parseFloat(mollie_repeat)).toFixed(2) }}</strong> € pendant <strong>{{ mollie_repeat }}</strong> mois</p>
                </template>
            </template>
        </vx-card>
        <br/>
        <br/>
        <vs-button color="primary" type="filled" v-on:click="retour()" >Retour</vs-button>
        <vs-button color="success" type="filled" class="right nowrap" v-on:click="create()" >Enregistrer prélévement</vs-button>
    </div>
</template>



<style lang="scss" scoped>
.right{
    display:inline-block;
    float:right;
}
</style>

<script>
//test: NL55INGB0000000000

import autoRefresh from '@/assets/utils/autoRefresh'

export default {
    data(){
        return{
            prelevement:'',
            mollie_iban:'',
            mollie_repeat:'',
        }
	},
	mounted:function()
	{
        //autoRefresh
		autoRefresh.SetCallback(()=>{
			//refresh todo
        })
        autoRefresh.refresh()
    },
    watch:{
        $route (){
            
        }
    },
    methods:{
        retour()
        {
            this.$router.push("/admin/member/"+this.$route.params.mbr).catch(() => {})
        },
        create()
        {
            //verif IBAN
            if(this.mollie_iban.length <= 14 )
                return alert('IBAN non complet !')

            //verif montant
            if(this.prelevement.length<1)
                return alert('Montant vide !')

            //verif repat
            if(Number(this.mollie_repeat) < 1 )
                return alert('Répétition minimum: 1')

            //payement mode mollie IBAN
            this.$srvApi.Req('post','mollie/iban',{
                member   : this.$route.params.mbr,
                month    : Number(this.mollie_repeat),
                amount   : this.prelevement,
                iban     : this.mollie_iban,
            })
            .then((rep)=>{
                if( rep.data.indexOf('sub_') == 0 )
                {
                    alert('Prévélevement enregistré')
                    this.$router.push("/admin/member/"+this.$route.params.mbr).catch(() => {})
                }
                else
                    alert( rep.data.split('Documentation')[0] );
            })
            .catch(()=>{
                //console.log( err )
                alert('Erreur API mollie')
            })
        },
    }
};

</script>